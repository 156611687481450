export const domains_en = {
    "my_domains": "My domains",
    "add_new_domain": "Add new domain",
    "add_pay_new_domain": "Add paid domain",
    "create_domain": "Create a domain",
    "create_paid_domain": "Create paid domain",
    "domain_name": "Domain name",
    "enter_value": "Enter the name",
    "cancel": "Cancel",
    "save": "Save",
    "pay": "Pay",
    "unpaid": "unpaid",
    "paying": "unpaid",
    "invoice_paid": "The invoice is paid!",
    "pay_invoice2": "Invoice payment",
    "confirm_payment": "I have already paid",
    "available_domains": "Domains available to create",
    "domains_limit": "You have created the maximum amount of free domains. You can add and pay for a domain.",
    "emails_addresses": "Emails addresses",
    "customize_dns": "Customize DNS",
    "removing_domain": "Removing the domain",
    "domain_verification": "Domain verification",
    "domain_being_verified": "Domain being verified",
    "domain_verified_successfully": "Domain verified successfully!",
    "checking_time": "Checking time",
    "failed_to_check": "Failed to check. Check type",
    "confirm": "Confirm",
    "click_confirm": "Click the 'Confirm' button",
    "dns_check": "Dns-check",
    "meta_tag": "Meta tag",
    "being_confirmed": "being confirmed",
    "confirmed": "confirmed",
    "not_confirmed": "not confirmed",
    "prepared": "not confirmed",
    "processing": "being confirmed",
    "failed": "confirmation error",
    "succeed": "activated",
    "waiting": "being confirmed",
    "domain_text1": "This method can be used even if there is no site on the domain. The new DNS entry will not affect the operation of the site or your current mail.",
    "domain_text2": "To add a TXT record, you must have access to edit the domain's DNS records. This is usually done through the web interface of the hosting provider or domain name registrar.",
    "domain_text3": "Add a DNS entry with the following values",
    "domain_text4": "Domain/subdomain name",
    "record_type": "Record type",
    "value1": "Value",
    "domain_text5": "Sometimes the DNS management interface does not allow the ",
    "domain_text5_1": " character to be used as a domain/subdomain name. In this case, use your domain name with a dot at the end.",
    "domain_text6": "Attention! Changing a DNS entry can take anywhere from a few minutes to several hours. If you have changed the entry recently, please wait a while.",
    "domain_text7": "This method can be used only if you have hosting (working website on the domain)",
    "domain_text8": "Add before closing tag",
    "domain_text9": "the following code",
    "download_html": "Download HTML file",
    "domain_text10": "Upload it to the root directory of your webserver",
    "domain_text11": "Make sure the downloaded file opens at",
    "domain_text12": "The domain is being processed.",
    "domain": "Domain",
    "removed": "was removed.",
    "domain_text13": "Setting up DNS for the domain",
    "domain_text14": "DNS is being checked",
    "domain_text15": "Open the domain settings on your registrar's website",
    "domain_text16": "Create a new MX record with the following field values",
    "domain_text17": "Subdomain name (or Host)",
    "domain_text18": "Value (together with a dot at the end)",
    "priority": "Priority",
    "verified": "verified",
    "not_verified": "not verified",
    "domain_text19": "Add a DNS TXT record with parameters",
    "domain_text20": "Value (in bold)",
    "domain_text21": "Wait until your DNS records are updated and click the 'Confirm' button",
    "domain_text22": "Adding an email address",
    "address": "address",
    "add_email": "Add a new email",
    "adding_paid_email": "Adding paid email",
    "domain_text23": "You have created the maximum amount of possible aliases. You can add and pay for an alias.",
    "add_paid_email": "Add paid email",
    "domain_text24": "Aliases to create available",
    "domain_text25": "Enter the word 'delete' for removing the domain",
    "domain_not_allowed": "You can specify one of the following domains: ",
    "request_event_processing": "Checking the domain confirmation.",
    alias_access_limit: 'Repeated request to change password',
    duplicate_domains: 'Duplicate domains',
    remove_delegation: 'Remove delegation',
    are_you_sure10: 'Are you sure you want to remove delegation for email {email}?'
}
