export const domains_ru = {
    "my_domains": "Мои домены",
    "add_new_domain": "Добавить новый домен",
    "add_pay_new_domain": "Добавить платный домен",
    "create_domain": "Создание домена",
    "create_paid_domain": "Создать платный домен",
    "domain_name": "Название домена",
    "enter_value": "Введите название",
    "cancel": "Отмена",
    "save": "Сохранить",
    "pay": "Оплатить",
    "unpaid": "не оплачен",
    "paying": "не оплачен",
    "confirm_payment": "Я уже оплатил",
    "invoice_paid": "Счет оплачен!",
    "pay_invoice2": "Оплата счета",
    "available_domains": "Доменов для создания доступно",
    "domains_limit": "У вас создано максимальное количество бесплатных доменов. Вы можете добавить и оплатить домен.",
    "emails_addresses": "Email-адреса",
    "customize_dns": "Настроить DNS",
    "removing_domain": "Удаление домена",
    "domain_verification": "Подтверждение домена",
    "domain_being_verified": "Домен подтверждается",
    "domain_verified_successfully": "Домен успешно подтвержден!",
    "checking_time": "Время проверки",
    "failed_to_check": "Проверка не пройдена. Тип проверки",
    "confirm": "Подтвердить",
    "click_confirm": "Нажмите кнопку 'Подтвердить'",
    "dns_check": "DNS-проверка",
    "meta_tag": "Мета-тег",
    "being_confirmed": "подтверждается",
    "confirmed": "подтвержден",
    "prepared": "не подтвержден",
    "processing": "подтверждается",
    "failed": "ошибка подтверждения",
    "succeed": "активен",
    "waiting": "подтверждается",
    "not_confirmed": "не подтвержден",
    "domain_text1": "Данный способ можно использовать даже при отсутствии сайта на домене. Новая DNS-запись не повлияет на работу сайта или вашей текущей почты.",
    "domain_text2": "Для добавления TXT-записи вы должны иметь доступ к редактированию DNS-записей домена. Обычно это делается через веб-интерфейс хостинг-провайдера или регистратора доменного имени.",
    "domain_text3": "Добавьте DNS-запись со следующими значениями",
    "domain_text4": "Имя домена/поддомена",
    "record_type": "Тип записи",
    "value1": "Значение",
    "domain_text5": "Иногда интерфейс управления DNS не позволяет использовать символ ",
    "domain_text5_1": " как имя домена/поддомена. В этом случае используйте имя вашего домена с точкой на конце.",
    "domain_text6": "Внимание! Изменение DNS-записи может занять от нескольких минут до нескольких часов. Если вы изменили запись недавно, пожалуйста, подождите некоторое время.",
    "domain_text7": "Данный способ можно использовать только при наличии хостинга (работающего сайта на домене)",
    "domain_text8": "Добавьте перед закрывающим тегом",
    "domain_text9": "следующий код",
    "download_html": "Скачайте HTML-файл",
    "domain_text10": "Загрузите его в корневой каталог вашего веб-сервера",
    "domain_text11": "Убедитесь, что загруженный файл открывается по адресу",
    "domain_text12": "Домен в процессе обработки.",
    "domain": "Домен",
    "removed": " удален.",
    "domain_text13": "Настройка DNS для домена",
    "domain_text14": "DNS проверяется",
    "domain_text15": "Откройте настройки домена на сайте вашего регистратора",
    "domain_text16": "Создайте новую MX-запись со следующими значениями полей",
    "domain_text17": "Имя поддомена (или Хост)",
    "domain_text18": "Значение (вместе с точкой в конце)",
    "priority": "Приоритет",
    "verified": "проверен",
    "not_verified": "не проверен",
    "domain_text19": "Добавьте DNS-запись типа TXT с параметрами",
    "domain_text20": "Значение (все выделенное жирным)",
    "domain_text21": "Дождитесь пока обновятся ваши DNS записи и нажмите кнопку 'Подтвердить'",
    "domain_text22": "Добавление Email-адреса",
    "address": "адрес",
    "add_email": "Добавить новый email",
    "adding_paid_email": "Добавление платного email",
    "domain_text23": "У вас создано максимальное количество бесплатных почтовых ящиков. Вы можете добавить и оплатить почтовый ящик.",
    "add_paid_email": "Добавить платный email",
    "domain_text24": "Псевдонимов для создания доступно",
    "domain_text25": "Введите слово delete для удаления домена",
    "domain_not_allowed": "Вы можете указать один из следующих доменов: ",
    "request_event_processing": "Проверяем подтверждение домена.",
    alias_access_limit: 'Повторный запрос на смену пароля',
    duplicate_domains: 'Повторяющиеся домены',
    remove_delegation: 'Снять делегирование',
    are_you_sure10: 'Вы уверены, что хотите снять делегирование для email {email}?'
}
