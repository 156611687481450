<template>
  <div class="new-mail" :class="{'new-mail--collapsed': isCollapsed, 'new-mail--fullscreen': fullscreen}">
      <base-dialog :show="!!error" :widthProgressBar='widthProgressBar' @close="handleError" title="An error occured!">
          <p>{{error}}</p></base-dialog>
    <div class="new-mail__container">
      <div class="new-mail__wrapper">
        <div class="new-mail__wrapper-inner">
      <form  id="upload" class="new-mail__form" @submit.prevent="onSendMail(false, false)" method="" action="">
      <header class="new-mail__header">
        <h3 class="new-mail__title">{{$t('new_msg')}}</h3>
        <ul class="new-mail__list">
          <li class="new-mail__item">
            <button class="button button--action" type="button" data-bs-toggle="tooltip"  :title="$t('collapse')" id="new-mail-collapse" 
            @click='collapseCreateMail'>
              <span class="button__icon icon icon-minus"></span></button>
          </li>
          <li class="new-mail__item">
            <button class="button button--action" type="button" data-bs-toggle="tooltip" :title="$t('in_full_screen')" id="new-mail-fullscreen" 
            @click='fullscreenCreateMail'>
              <span class="button__icon icon"
                :class="fullscreen ? 'icon-scale' : 'icon-full'"></span></button>
          </li>
          <li class="new-mail__item">
            <button class="button button--action" type="button" data-bs-toggle="tooltip" :title="$t('close')" 
            @click='onSendMail(true, false)' id="new-mail-close"><span class="button__icon icon icon-close"></span></button>
          </li>
        </ul>
        <div class="new-mail__line"><span class="new-mail__label">{{$t('from2')}}</span>
          <div class="custom-select">
            <vue-select :select_values="aliasesEmails"
              :_values="aliasesId"
              :actual_value="search_alias"
              @change_value="setNewSearchAlias"
            ></vue-select>
          </div>
        </div>
        <div class="new-mail__accordion" id="new-mail-accordion">
          <div class="new-mail__line new-mail__line--to" @click="focus_to"><span class="new-mail__label">{{$t('to')}}</span>
            <div class="new-mail__tags">
                <div class="tag" v-for="(el, index) in receivers_emails.slice(0,2)" :key="el.email">
                  <span class="tag__icon icon icon-contacts"></span>
                  <span class="tag__name">{{el.email}}</span>
                  <span class="tag__close icon icon-close" v-on:click="removeReceiverToWhom(index)"></span>
                </div>
                <div v-if="receivers_emails.length > 2" class="new-mail__dropdown">
                  <button class="new-mail__button new-mail__button--toggle" 
                    :class="{'new-mail__button--active': is_active_receivers_emails_dropdown}"
                    @click="setActiveReceiversEmailsDropdown"
                    ref="receiversEmailsButton"
                    type="button"><span class="new-mail__icon icon icon-contacts"></span><span class="new-mail__counter">+{{receivers_emails.length-2}}</span></button>
                  <ul class="new-mail__dropdown-menu "
                    :class="{'new-mail__dropdown-menu--active': is_active_receivers_emails_dropdown}"
                    ref="receiversEmailsDropdown">
                    <li class="new-mail__item" v-for="(el, index) in receivers_emails.slice(2)" :key="el.email">
                      <div class="tag"><span class="tag__icon icon icon-contacts"></span><span class="tag__name">{{el.email}}</span><span class="tag__close icon icon-close" v-on:click="removeReceiverToWhom(index+2)"></span></div>
                    </li>
                  </ul>
                </div>
                <div class="new-mail__dropdown new-mail__dropdown--type-container">   
                  <input type="text" class="tag-input" 
                    autocomplete="off"
                    id="tab1"
                    @keydown.enter.prevent="$event.target.blur(); $event.target.focus()"   
                    v-model.trim="to_whom" @blur="blur_to_whom" @focus="focus_to_whom">
                  <ul class="new-mail__dropdown-menu contact_list_to"
                    :class="{'new-mail__dropdown-menu--active': contacts_list.length > 0}">
                    <li class="new-mail__item" v-for="contact in contacts_list" :key="contact.id"
                      @click="selectContactToWhom(contact)">
                      <div class="user">
                        <div class="user__avatar-wrapper">
                          <mail-avatar :email="contact.email || ''" :name="contact.name || ''" class="avatar"></mail-avatar>               
                        </div><a class="user__nickname" href="#">{{contact.name || contact.email}}</a><a class="user__email" href="#">{{contact.email}}</a>
                      </div>
                    </li>
                  </ul>
                </div>
            </div>
          </div>
          <button class="new-mail__button new-mail__button--accordion collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#new-mail-accordion-inner" @click="showOtherInputs = !showOtherInputs"><span class="new-mail__icon icon icon-arrow-dropdown"></span></button>
          <div class="new-mail__accordion-content collapse" id="new-mail-accordion-inner" data-bs-parent="#new-mail-accordion">
            <div class="new-mail__line"  @click="focus_copy2"><span class="new-mail__label">{{$t('copy')}}</span>
              <div class="new-mail__tags">
                <div class="tag" v-for="(el, index) in receivers_emails_copy.slice(0,2)" :key="el.email">
                  <span class="tag__icon icon icon-contacts"></span>
                  <span class="tag__name">{{el.email}}</span>
                  <span class="tag__close icon icon-close" v-on:click="removeReceiverCopy(index)"></span>
                </div>
                <div v-if="receivers_emails_copy.length > 2" class="new-mail__dropdown">
                  <button class="new-mail__button new-mail__button--toggle" 
                    :class="{'new-mail__button--active': is_active_receivers_emails_copy_dropdown}"
                    @click="setActiveReceiversEmailsCopyDropdown"
                    ref="receiversEmailsCopyButton"
                    type="button"><span class="new-mail__icon icon icon-contacts"></span><span class="new-mail__counter">+{{receivers_emails_copy.length-2}}</span></button>                 
                  <ul class="new-mail__dropdown-menu "
                    :class="{'new-mail__dropdown-menu--active': is_active_receivers_emails_copy_dropdown}"
                    ref="receiversEmailsCopyDropdown">
                    <li class="new-mail__item" v-for="(el, index) in receivers_emails_copy.slice(2)" :key="el.email">
                      <div class="tag"><span class="tag__icon icon icon-contacts"></span><span class="tag__name">{{el.email}}</span>
                      <span class="tag__close icon icon-close" v-on:click="removeReceiverCopy(index+2)"></span></div>
                    </li>
                  </ul>
                </div>                
                <div class="new-mail__dropdown new-mail__dropdown--type-container">    
                  <input type="text" class="tag-input" 
                    autocomplete="off"
                    id="tab2"
                    @keydown.enter.prevent="$event.target.blur(); $event.target.focus()"    
                    v-model="copy" @blur="blur_copy" @focus="focus_copy" >
                  <ul class="new-mail__dropdown-menu"
                    :class="{'new-mail__dropdown-menu--active': contacts_list_copy.length > 0}">
                    <li class="new-mail__item" v-for="contact in contacts_list_copy" :key="contact.id"
                      @click="selectContactCopy(contact)">
                      <div class="user">
                        <div class="user__avatar-wrapper">
                          <mail-avatar :email="contact.email || ''" :name="contact.name || ''" class="avatar"></mail-avatar>               
                        </div><a class="user__nickname" href="#">{{contact.name || contact.email}}</a><a class="user__email" href="#">{{contact.email}}</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="new-mail__line"   @click="focus_hid_copy"><span class="new-mail__label">{{$t('hidden_copy')}}</span>
              <div class="new-mail__tags">
                <div class="tag" v-for="(el, index) in receivers_emails_hidden_copy.slice(0,2)" :key="el.email">
                  <span class="tag__icon icon icon-contacts"></span>
                  <span class="tag__name">{{el.email}}</span>
                  <span class="tag__close icon icon-close" v-on:click="removeReceiverHiddenCopy(index)"></span>
                </div>       
                <div v-if="receivers_emails_hidden_copy.length > 2" class="new-mail__dropdown">
                  <button class="new-mail__button new-mail__button--toggle" 
                    :class="{'new-mail__button--active': is_active_receivers_emails_hidden_copy_dropdown}"
                    @click="setActiveReceiversEmailsHiddenCopyDropdown"
                    ref="receiversEmailsHiddenCopyButton"
                    type="button"><span class="new-mail__icon icon icon-contacts"></span>
                    <span class="new-mail__counter">+{{receivers_emails_hidden_copy.length-2}}</span></button>                
                  <ul class="new-mail__dropdown-menu "
                    :class="{'new-mail__dropdown-menu--active': is_active_receivers_emails_hidden_copy_dropdown}"
                    ref="receiversEmailsHiddenCopyDropdown">
                    <li class="new-mail__item" v-for="(el, index) in receivers_emails_hidden_copy.slice(2)" :key="el.email">
                      <div class="tag"><span class="tag__icon icon icon-contacts"></span><span class="tag__name">{{el.email}}</span><span class="tag__close icon icon-close" v-on:click="removeReceiverHiddenCopy(index+2)"></span></div>
                    </li>
                  </ul>
                </div>
                <div class="new-mail__dropdown new-mail__dropdown--type-container">
                  <input type="text" class="tag-input"
                    autocomplete="off"
                    id="tab3"
                    @keydown.enter.prevent="$event.target.blur(); $event.target.focus()"     
                    v-model="hidden_copy" @blur="blur_hidden_copy" @focus="focus_hidden_copy">
                  <ul class="new-mail__dropdown-menu"
                    :class="{'new-mail__dropdown-menu--active': contacts_list_hidden_copy.length > 0}">
                    <li class="new-mail__item" v-for="contact in contacts_list_hidden_copy" :key="contact.id"
                      @click="selectContactHiddenCopy(contact)">
                      <div class="user">
                        <div class="user__avatar-wrapper">
                          <mail-avatar :email="contact.email || ''" :name="contact.name || ''" class="avatar"></mail-avatar>               
                        </div><a class="user__nickname" href="#">{{contact.name || contact.email}}</a><a class="user__email" href="#">{{contact.email}}</a>
                      </div>
                    </li>
                  </ul>
                </div>             
              </div>
            </div>
            <div class="new-mail__line"   @click="focus_whom_to"><span class="new-mail__label">{{$t('whom_to_answer')}}</span>
              <div class="new-mail__tags">
                <div class="tag" v-for="(el, index) in receivers_emails_whom_to_answer.slice(0,2)" :key="el.email">
                  <span class="tag__icon icon icon-contacts"></span>
                  <span class="tag__name">{{el.email}}</span>
                  <span class="tag__close icon icon-close" v-on:click="removeReceiverWhomToAnswer(index)"></span>
                </div>
                <div v-if="receivers_emails_whom_to_answer.length > 2" class="new-mail__dropdown">
                  <button class="new-mail__button new-mail__button--toggle" 
                    :class="{'new-mail__button--active': is_active_receivers_emails_whom_to_answer_dropdown}"
                    @click="setActiveReceiversEmailsWhomToAnswerDropdown"
                    ref="receiversEmailsWhomToAnswerButton"
                    type="button"><span class="new-mail__icon icon icon-contacts"></span><span class="new-mail__counter">+{{receivers_emails_whom_to_answer.length-2}}</span></button>
                  <ul class="new-mail__dropdown-menu "
                    :class="{'new-mail__dropdown-menu--active': is_active_receivers_emails_whom_to_answer_dropdown}"
                    ref="receiversEmailsWhomToAnswerDropdown">
                    <li class="new-mail__item" v-for="(el, index) in receivers_emails_whom_to_answer.slice(2)" :key="el.email">
                      <div class="tag"><span class="tag__icon icon icon-contacts"></span><span class="tag__name">{{el.email}}</span>
                      <span class="tag__close icon icon-close" v-on:click="removeReceiverWhomToAnswer(index+2)"></span></div>
                    </li>
                  </ul>
                </div>
                <div class="new-mail__dropdown new-mail__dropdown--type-container">
                  <input type="text" class="tag-input"
                    autocomplete="off"
                    id="tab4"
                    @keydown.enter.prevent="$event.target.blur(); $event.target.focus()"    
                    v-model="whom_to_answer" @blur="blur_whom_to_answer" @focus="focus_whom_to_answer">  
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="new-mail__line new-mail__line--title">
          <label class="new-mail__label" for="tab5">{{$t('subject')}}</label>
          <input class="new-mail__mail-title" type="text" 
            autocomplete="off"
            id="tab5"
            @keydown.enter.prevent="$event.target.blur(); $event.target.focus()"    
            name="title-field" v-model="mail_subject">
        </div>
      </header>
       <div class="new-mail__content">
        <div class="new-mail__area">
          <textarea v-if="!isHTML"
                    id="tab6"
          v-model="text2"
            class="textarea_class"></textarea>
          <div
            v-show="isHTML"
            class="ckeditor_wrapper">
            <ckeditor
            ref="editor1"
              :editor="editor" 
              v-model="text" 
              @ready="onEditorReady"
              :config="editorConfig">
            </ckeditor>
          </div>
        </div>
        <div class="new-mail__attachments" v-if="savedAttachments.length > 0"> 
          <!-- attach--error attach--success-->
          <div class="attach saved_attachments"  v-for="(attach,i) in savedAttachments" :key="attach.name"> 
            <div class="attach__preview "> 
              <!-- <img  src="images/Attach-preview.png" /> -->
              <div class="attach__image icon"
                :class="'icon-format-'+fileExt2(attach.name)"></div>
            </div>
            <div class="attach__row">
              <div class="attach__progress">
              </div>
              <!-- <span class="dz-upload" data-dz-uploadprogress></span> -->
            </div>
            <div class="attach__row"> 
              <p class="attach__name">
              <span class="attach_name" tabindex="0">{{attach.name}}</span>
              </p>
              <button class="button button--action" @click="removeSavedAttach(i)"><span class="button__icon icon icon-close"></span></button>
            </div>
            <div class="attach__row">
              <div class="attach__error">
                <span class="attach__error-text" tabindex="0"></span>
              </div>        
              <div class="attach__weight">{{calculateSize(attach.size)}}</div>
            </div>
          </div>
        </div>
        <div id="previewsContainer" class="new-mail__attachments"> 
              <!-- :class="{error_class: !!image.error}" -->
        </div>
   </div>
      <footer class="new-mail__footer"> 
          <button id="add_attachments" class="new-mail__button new-mail__button--add dropzone" :title="$t('add_attachments')" >
            <span class="new-mail__icon icon icon-attachment"></span>
            <!-- <img src="images/icons/File.svg"> -->
          </button>
          <div class="dropdown dropdown--element" v-if="isAvailableModeButton">
            <button class="dropdown__button dropdown__button--element" type="button" data-bs-toggle="dropdown" aria-expanded="false" :title="$t('additional_options')"><span class="dropdown__icon icon icon-dots"></span>
            </button>
            <ul class="dropdown__menu dropdown-menu">
              <li class="dropdown__item" @click="changeTextMode(false)"><span class="dropdown__name">{{ $t('text') }}</span>
              </li>
              <li class="dropdown__item" @click="changeTextMode(true)"><span class="dropdown__name">HTML</span>
              </li>
            </ul>
          </div>
          <div class="new-mail__encrypt">
            <input class="new-mail__text new-mail__text--password" v-if="encrypt" v-model="code" 
              minlength="3"
              maxlength="20" type="text" :placeholder="$t('enter_password')">
            <button class="new-mail__button new-mail__button--copy" v-if="encrypt" @click.prevent="copy_func_handler(code, 'code')">
              <span class="new-mail__icon icon icon-copy"></span>
              <span class="copied" v-if="copied['code']">{{ $t('copied') }}</span>
            </button>
            <span class="new-mail__text new-mail__text--default"  v-if="!encrypt">{{ $t('encrypt') }}</span>
            <label class="switcher switcher--modified">
              <input class="switcher__checkbox" type="checkbox" v-model="encrypt" @change="changeEncrypt"><span class="switcher__slider"></span>
            </label>
          </div>
          <button class="button button--secondary" type="submit"
            :disabled="processingAttaches.length > 0">
            <span class="button__text icon icon-send" v-if="!is_sending_mail">{{$t('send')}}</span>
            <second-spinner v-if="is_sending_mail" class="creating_mail_btn"
                            :class="{'mobile_creating_mail_btn': isMobile}"></second-spinner>
          </button>
      </footer>
      <div class="cover" v-if="is_sending_mail" style="background: rgb(176 186 216 / 64%);"></div>
      <!-- <base-spinner v-if="is_sending_mail" class="spinner"></base-spinner> -->
      </form> 
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/ru';
import {bytesToSize} from '../../helpers/Size'
import MailAvatar from '../mail_item/MailAvatar.vue'
import Dropzone from "dropzone";
import VueSelect from '../../helpers/VueSelect.vue'
import { createPopper } from '@popperjs/core'; 
import { strip_tags } from '../../helpers/tags'
import { makeid, validateEmail } from '../../helpers/Useful_methods'
import PushMixin from '../../mixins/PushMixin';
import {fileExt} from "@/components/helpers/Useful_methods";
import {copy_func} from '@/components/helpers/copy_function'
export default {
  name: "CreateMail",
  emits: ["close_create_mail"],
  components: {
    VueSelect,
    MailAvatar
  },
  mixins: [PushMixin],
  props: {
    mail: Object,
    to_all: Number,
    email: String,
    lang: String,
    email_to: String,
    account: Object,
    subfolders: Array,
    is_sending_mail: Boolean
  },
  data() {
    return {
      mail_subject: "",
      text: "",
      text2: "",
      from: "",
      to_whom: "",
      to_whom_error: false,
      copy: "",
      copy_error: false,
      copyIsOpen: false,
      hidden_copy: "",
      hidden_copy_error: false,
      hiddenCopyIsOpen: false,
      whom_to_answer: "",
      whom_to_answer_error: false,
      whomToAnswerIsOpen: false,
      receivers_emails: [],
      receivers_emails_copy: [],
      receivers_emails_hidden_copy: [],
      receivers_emails_whom_to_answer: [],
      error: null,
      isLoading: false,
      editor: ClassicEditor,
      editorConfig: { 
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'blockQuote',
            'insertTable',
            '|',
            'undo',
            'redo'
          ]
        },
        language: 'ru'
      },
      images: [],
      attachments: [],
      savedAttachments: [],
      files: [],
      urlFileUpload: '',
      accountAliases: [],
      aliasesEmails: [],
      aliasesId: [],
      search_alias: '',
      profiles: [],
      fullscreen: false,
      isCollapsed: false,
      is_active_receivers_emails_dropdown: false,
      is_active_receivers_emails_copy_dropdown: false,
      is_active_receivers_emails_hidden_copy_dropdown: false,
      is_active_receivers_emails_whom_to_answer_dropdown: false,
      interval: null,
      message_id: '',
      widthProgressBar: 0,
      contacts_list: [],
      contacts_list_copy: [],
      contacts_list_hidden_copy: [],
      controller: null,
      isHTML: true,
      isAvailableModeButton: true,
      tab: 0,
      showOtherInputs: false,
      settings: {},
      showSignatureSettings: false,
      encrypt: false,
      code: '',
      copied: {},
      isMobile: false,
      attachIsProcessing: false,
      processingAttaches: [],
      userAlias: ''
    };
  },
  watch: {
    to_whom: function(val, oldVal){
      if(this.userAlias)
        return
      if(val!==oldVal && val !== ''){
        this.getContactsList(val, 1)
      }
      if(val === ''){
        this.contacts_list=[]
      }
    },
    copy: function(val, oldVal){
      if(this.userAlias)
        return
      if(val!==oldVal && val !== ''){
        this.getContactsList(val, 2)
      }
      if(val === ''){
        this.contacts_list_copy=[]
      }
    },
    hidden_copy: function(val, oldVal){
      if(this.userAlias)
        return
      if(val!==oldVal && val !== ''){
        this.getContactsList(val, 3)
      }
      if(val === ''){
        this.contacts_list_hidden_copy=[]
      }
    },
  },
  methods: {
    copy_func_handler(text, icon){
      copy_func(this.copied, text, icon)
    },
    fileExt2(name){
      if(name){
        return fileExt(name.split('.').pop())
      }
      return fileExt('')
    },
    focus_to(){
      const elem = document.getElementById(`tab1`)
      if(elem){
          elem.focus()
      }
    },
    focus_copy2(){
      const elem = document.getElementById(`tab2`)
      if(elem){
          elem.focus()
      }
    },
    focus_hid_copy(){
      const elem = document.getElementById(`tab3`)
      if(elem){
          elem.focus()
      }
    },
    focus_whom_to(){
      const elem = document.getElementById(`tab4`)
      if(elem){
          elem.focus()
      }
    },
    // ctrlEnter(e){
    //   e.preventDefault()
    //   console.log('e.target :>> ', e.target);
    // },
    // changeEncrypt(e){
    //   console.log('this.encrypt :>> ', this.encrypt);
    //   console.log('e.target.value :>> ', e.target.value);
    //   // this.encrypt = 
    // },
    removeSavedAttach(i){
      this.savedAttachments.splice(i,1)
    },
    changeTextMode(val){
      if(this.isHTML === val){
        return
      } else{
        this.isHTML = val
      }
      let signature = ''
      const j = this.profiles.findIndex(el => el.name+' <'+el.email+'>' === this.search_alias)
      if(j!==-1 && this.profiles[j].signature){
        signature = this.profiles[j].signature
      }
      let line = '---'
      if(signature){
        const res1 = signature.split("---")
        if(res1.length > 1){
          line = ''
        }
      }
      let result = []
      if(this.isHTML){
        result = this.text2.replace(/\n/g, '<br />').split('---')
      }else{
        let str = this.text.replace(/<br \/>|<br>/g,'\n').replace(/&gt;/g, '>').replace(/&nbsp;/g, ' ')
        // .replace(/<p>|<h2>|<\/h2>|<h3>|<\/h3>|<h4>|<\/h4>|<strong>|<\/strong>|<i>|<\/i>|<figure class="table">|<table>|<tbody>|<tr>|<td>|<\/td>|<\/tr>|<\/tbody>|<\/table>|<\/figure>|<blockquote>|<\/blockquote>|<ol>|<li>|<\/li>|<\/ol>|<ul>|<\/ul>/g, '')
          str = strip_tags(str)
          result = str.split('---')
      }
      const k = result.length
      if(k > 1){
        if(this.isHTML){
            if(signature && this.showSignatureSettings){
              if(line===''){
                result[k-2] = result[k-2] + signature
                result.pop()
              }else{
                result[k-1] =  signature
              }
            }
            this.text = result.join('---')
            // this.text = this.text.replace('\n', '<br />')
        } else{
          if(signature && this.showSignatureSettings){
            signature = signature.replace('<br />', '\n').replace(/&nbsp;/g, '\n')
            signature = strip_tags(signature)
            if(line===''){
              result[k-2] = result[k-2] + '\n' + signature
              result.pop()
            }else{
              result[k-1] = '\n' + signature
            }
          }
            this.text2 = result.join('---')
        }
      }else{
        if(this.isHTML){
          this.text = result.join('---')
        }else{
          this.text2 = result.join('---')
        }
      }
    },
    selectContactToWhom(contact){
      this.receivers_emails.push({email: contact.email, name: contact.name})
      this.to_whom=''
      this.contacts_list=[]
      this.to_whom_error = false
    },
    selectContactCopy(contact){
      this.receivers_emails_copy.push({email: contact.email, name: contact.name})
      this.copy=''
      this.contacts_list_copy=[]
      this.copy_error = false
    },
    selectContactHiddenCopy(contact){
      this.receivers_emails_hidden_copy.push({email: contact.email, name: contact.name})
      this.hidden_copy=''
      this.contacts_list_hidden_copy=[]
      this.hidden_copy_error = false
    },
    async getContactsList(str, k){
      // this.isLoading = true;
      if(this.controller){
        this.controller.abort()
        this.controller = null
      }
      this.controller = new AbortController()
      const actionPayload = {
        search: str,
        controller: this.controller
      };
      try {
        const resp = await this.$store.dispatch("getContactsList", actionPayload);
        this.controller = null
        let arr = []
        if(resp && resp.length > 0) arr = resp
        if(k === 1) this.contacts_list = arr
        else if(k === 2) this.contacts_list_copy = arr
        else if(k === 3) this.contacts_list_hidden_copy = arr
      }catch (err) {
        this.errorHandler(err)
        return []
      }
      // this.isLoading = false;
    },
    setActiveReceiversEmailsWhomToAnswerDropdown(){
      this.is_active_receivers_emails_whom_to_answer_dropdown = !this.is_active_receivers_emails_whom_to_answer_dropdown

        let popper = createPopper(this.$refs.receiversEmailsWhomToAnswerButton, this.$refs.receiversEmailsWhomToAnswerDropdown, {
            placement: 'bottom-start',
        })
        popper.update()
    },
    setActiveReceiversEmailsHiddenCopyDropdown(){
      this.is_active_receivers_emails_hidden_copy_dropdown = !this.is_active_receivers_emails_hidden_copy_dropdown

        let popper = createPopper(this.$refs.receiversEmailsHiddenCopyButton, this.$refs.receiversEmailsHiddenCopyDropdown, {
            placement: 'bottom-start',
        })
        popper.update()
    },
    setActiveReceiversEmailsCopyDropdown(){
      this.is_active_receivers_emails_copy_dropdown = !this.is_active_receivers_emails_copy_dropdown

        let popper = createPopper(this.$refs.receiversEmailsCopyButton, this.$refs.receiversEmailsCopyDropdown, {
            placement: 'bottom-start',
        })
            popper.update()
    },
    setActiveReceiversEmailsDropdown(){
      this.is_active_receivers_emails_dropdown = !this.is_active_receivers_emails_dropdown

        let popper = createPopper(this.$refs.receiversEmailsButton, this.$refs.receiversEmailsDropdown, {
            placement: 'bottom-start',
        })
            popper.update()
    },
    widthPB(){
        const step = 1
        let interval = setInterval(()=>{
          this.widthProgressBar+=step
        },40)
        setTimeout(()=>{
          this.error=null
          clearInterval(interval)
          this.widthProgressBar = 0
          }, 4000)
    },
    handleError(){
      this.error = null;
      this.isLoading = false;
    },
    calculateSize(bytes){
      return bytesToSize(+bytes)
    },
    removeAttach(i){
      const index = this.attachments.findIndex(el => el.name === this.images[i].name)
      if(index !== -1){
        this.attachments.splice(index, 1)
      }
      this.images.splice(i, 1)
    },
    async onSendMail(isDraft, isAutoSave) {
      if((this.to_whom_error||this.copy_error||this.hidden_copy_error||this.whom_to_answer_error) && !isDraft){
        let err_arr = []
        if(this.to_whom_error){
          err_arr.push(this.$t("recipient2"))
        }
        if(this.copy_error){
          err_arr.push(this.$t('cc'))
        }
        if(this.hidden_copy_error){
          err_arr.push(this.$t('bcc'))
        }
        if(this.whom_to_answer_error){
          err_arr.push(this.$t('reply_to'))
        }
        let str = this.$t("invalid_email_for")
        str = str + err_arr.join(', ')+'.'
        this.error = str
        this.widthPB()
        return;
      }
      if(this.receivers_emails.length === 0 && !isDraft){
        this.to_whom_error = true
        this.error=this.$t('email_not_specified')
        this.widthPB()
        return
      }
      if(this.receivers_emails.length === 0 && isDraft && this.mail_subject === "" && this.text === "" && !isAutoSave && this.attachments.length === 0){
        clearInterval(this.interval)
        this.$emit("close_create_mail", !isDraft, true, null);
        return
      }
      if(this.receivers_emails.length === 0 && isDraft && this.mail_subject === "" && this.text === "" && isAutoSave && this.attachments.length === 0){
        return
      }
      let fields = {bcc: [], cc: [], from: [], 'reply-to': [], to: []}
      let email = ''
      if(this.accountAliases.length > 0){
        email = this.accountAliases[0].email
      }
      const storeEmail = this.$store.getters['getLeftSidebarSelect']
      if(storeEmail && storeEmail !== this.$t('all_messages')){
        email = storeEmail
      }
      let name = ''
      if(this.search_alias){
        const i = this.accountAliases.findIndex(el => el.email === this.search_alias)
        if(i!==-1){
          email = this.search_alias
        } else{
          const k = this.profiles.findIndex(el => el.name+' <'+el.email+'>' === this.search_alias)
          if(k!==-1){
            email = this.profiles[k].email
            name = this.profiles[k].name
          }
        }
      }
      fields.from.push({
        email: email,
        name: name
      })
      if(this.receivers_emails.length > 0){
        this.receivers_emails.forEach(el => {
          fields.to.push({
            email: el.email,
            name: el.name || ''
          })
        })
      }
      if(this.receivers_emails_copy.length > 0){
        this.receivers_emails_copy.forEach(el => {
          fields.cc.push({
            email: el.email,
            name: el.name || ''
          })
        })
      }
      if(this.receivers_emails_hidden_copy.length > 0){
        this.receivers_emails_hidden_copy.forEach(el => {
          fields.bcc.push({
            email: el.email,
            name: el.name || ''
          })
        })
      }
      if(this.mail.fields && this.mail.fields.bcc && this.mail.fields.bcc.length > 0){
        this.mail.fields.bcc.forEach(el => {
          fields.bcc.push({
            email: el.email,
            name: el.name || ''
          })
        })
      }
      if(this.receivers_emails_whom_to_answer.length > 0){
        this.receivers_emails_whom_to_answer.forEach(el => {
          fields['reply-to'].push({
            email: el.email,
            name: el.name || ''
          })
        })
      }
      // let message_id = ''
      // if(this.mail && this.mail.message_id){
      //   message_id = this.mail.message_id
      // }else {
      //   message_id = this.message_id
      // }
      let attachments = []
      attachments = this.savedAttachments.concat(this.attachments)

      let content_type = "text/plain"
      let text = this.text2
      if(this.isHTML){
        content_type = "text/html"
        text = this.text
      }
      let actionPayload = {
        body: {
          subject: this.mail_subject,
          fields: fields,
          content: btoa(unescape(encodeURIComponent(text))),
          content_type: content_type,
          // message_id: message_id,
          attachments: attachments
        },
        isDraft: isDraft
      };
      if(this.mail && this.mail.uid){
        actionPayload.body['uid'] = this.mail.uid
      }
      if(!isAutoSave){
        clearInterval(this.interval)
        // this.text = ''
        // this.subject = ''
        // this.receivers_emails = []
        // this.receivers_emails_copy = []
        // this.receivers_emails_hidden_copy = []
        // this.receivers_emails_whom_to_answer = []
        // this.attachments = []
        // this.savedAttachments = []
      }
      if(this.encrypt){
        actionPayload['body']['encrypt']=true
        actionPayload['body']['password']=this.code
      }
      this.$emit("close_create_mail", !isDraft, isAutoSave, actionPayload);
    },
    removeReceiverToWhom(i){
      this.receivers_emails.splice(i, 1)
    },
    removeReceiverCopy(i){
      this.receivers_emails_copy.splice(i,1)
    },
    removeReceiverHiddenCopy(i){
      this.receivers_emails_hidden_copy.splice(i, 1)
    },
    removeReceiverWhomToAnswer(i){
      this.receivers_emails_whom_to_answer.splice(i, 1)
    },
    textForResend(){
      let date = ''
      if(this.mail && this.mail.created_at){
        date = new Date(this.mail.created_at);
        let results = ''
        try{
          results = date.toLocaleString(this.lang, {hour: "2-digit", minute:"2-digit", second:"2-digit", day: '2-digit', month: 'short' });
          date = results
        }catch(e){
          results = date.toUTCString().split(" ");
          date = results[4] + " " + results[1] + " " + results[2];
        }
      }
      let subject = ''
      if(this.mail && this.mail.subject){
        subject = this.mail.subject
      }
      let from = ''
      if(this.mail?.fields?.from && this.mail?.fields?.from.length > 0){
        this.mail.fields.from.forEach((el, i) => {
          from += el.email
          if(i !== this.mail.fields.from.length-1){
            from += ", "
          }
        })
      }
      let to = ''
      if(this.mail?.fields?.to && this.mail?.fields?.to.length > 0){
        this.mail.fields.to.forEach((el, i) => {
          to += el.email
          if(i !== this.mail.fields.to.length-1){
            to += ", "
          }
        })
      }
      let content = ''
      if(this.mail && this.mail.content){
        try{
          if(this.mail.encrypted){
            content = this.mail.content
          }else{
            content = decodeURIComponent(escape(atob(this.mail.content)))
          }
        } catch (err){
          console.log("error", err.message)
          content = ''          
        }
      }
      let final_text = ""
      if(this.isHTML){
        final_text = "<br /><br />-------- " + 
        this.$t('original_message') + 
        " --------<br />"+
        this.$t('subject')+" "+subject+"<br />"+
        this.$t('date')+" "+date+"<br />"+
        this.$t('from2')+" "+ from +"<br />"+
        this.$t('to')+ " " + to + "<br />"+"<br />"+content
      } else{
        final_text = `

-------- ` + 
        this.$t('original_message') + 
        ` --------
`+
        this.$t('subject')+" "+subject+`
`+
        this.$t('date')+" "+date+`
`+
        this.$t('from2')+" "+ from +`
`+
        this.$t('to')+ " " + to + `

`+content
      }
      return final_text
    },
    blur_to_whom(){
      // setTimeout(()=>{
      //   this.contacts_list = []
      // },100)
      if(this.to_whom.length > 0){
        const arr = this.to_whom.split(/[\s,]+/)
        const arr2 = []
        let new_to_whom = []
        arr.forEach(el => {
          if(validateEmail(el)){
            arr2.push({email: el, name: ''})
            this.to_whom_error = false
          } else if(el !== ''){
            new_to_whom.push(el)
            this.to_whom_error = true
          }
        })
        this.to_whom = new_to_whom.join(', ')
        this.receivers_emails = this.receivers_emails.concat(arr2)
      }
    },
    focus_to_whom(){
      this.to_whom_error = false
      this.isNoReceivers = false
      this.contacts_list_copy = []
      this.contacts_list_hidden_copy = []
    },
    blur_whom_to_answer(){
      if(this.whom_to_answer.length > 0){
        const arr = this.whom_to_answer.split(/[\s,]+/)
        const arr2 = []
        let new_whom_to_answer = []
        arr.forEach(el => {
          if(validateEmail(el)){
            arr2.push({email: el, name: ''})
          } else{ 
            new_whom_to_answer.push(el)
            this.whom_to_answer_error = true
          }
        })
        this.whom_to_answer = new_whom_to_answer.join(', ')
        this.receivers_emails_whom_to_answer = this.receivers_emails_whom_to_answer.concat(arr2)
      }
    },
    focus_whom_to_answer(){
      this.whom_to_answer_error = false
    },
    blur_copy(){
      if(this.copy.length > 0){
        const arr = this.copy.split(/[\s,]+/)
        const arr2 = []
        let new_copy = []
        arr.forEach(el => {
          if(validateEmail(el)){
            arr2.push({email: el, name: ''})
          }else{
            new_copy.push(el)
            this.copy_error = true
          }
        })
        this.copy = new_copy.join(', ')
        this.receivers_emails_copy = this.receivers_emails_copy.concat(arr2)
      }
    },
    focus_copy(){
      this.copy_error = false
      this.contacts_list = []
      this.contacts_list_hidden_copy = []
    },
    blur_hidden_copy(){
      if(this.hidden_copy.length > 0){
        const arr = this.hidden_copy.split(/[\s,]+/)
        const arr2 = []
        let new_hidden_copy = []
        arr.forEach(el => {
          if(validateEmail(el)){
            arr2.push({email: el, name: ''})
          }else{
            new_hidden_copy.push(el)
            this.hidden_copy_error = true
          }
        })
        this.hidden_copy = new_hidden_copy.join(', ')
        this.receivers_emails_hidden_copy = this.receivers_emails_hidden_copy.concat(arr2)
      }
    },
    focus_hidden_copy(){
      this.hidden_copy_error = false
      this.contacts_list = []
      this.contacts_list_copy = []
    },
    fullscreenCreateMail(){
      // if(!this.fullscreen && this.isCollapsed){
      //   console.log('this.mail :>> ', this.mail);
      //   this.initMail()
      // }
      if(!this.fullscreen){
        this.fullscreen = true
        this.isCollapsed = false
      } else{
        this.fullscreen = false
        this.isCollapsed = false
      }
    },
    collapseCreateMail(){
      this.isCollapsed = true
      this.fullscreen = false
    },
    getAttachLink(id){
      if(this.mail.uid && this.mail.folder_id){
        return this.$store.getters["url1"]+'attachments/'+id +
        '?message_uid=' + this.mail.uid + '&folder_id=' + this.mail.folder_id;
      }
      return ''
    },
    setNewSearchAlias(val){
      const i = this.aliasesId.indexOf(val)
      if(i!==-1){
        const previousAlias = this.search_alias
        let hasSignature = false
        const p = this.profiles.findIndex(el => el.name+' <'+el.email+'>' === previousAlias)
        if(p!==-1 && this.profiles[p].signature){
          hasSignature = true
        }
        this.search_alias = this.aliasesEmails[i]
        let result = [] 
        if(this.isHTML){
          result = this.text.split('---')
        } else{
          result = this.text2.split('---')
        }
        let signature = ''
        const j = this.profiles.findIndex(el => el.name+' <'+el.email+'>' === this.search_alias)
        if(j!==-1 && this.profiles[j].signature){
          signature = this.profiles[j].signature
        }

        let line = '---'
        if(signature && this.showSignatureSettings){
          const res1 = signature.split("---")
          if(res1.length > 1){
            line = ''
          }
        }
        const k = result.length
        if(this.isHTML){
          if(k > 1){
            if(signature && this.showSignatureSettings){
              if(hasSignature){
                if(line===''){
                  result[k-2] = result[k-2]  + signature
                  result.pop()
                }else{
                  result[k-1] = "<br />" + signature
                }
              }else{
                if(line===''){
                  result[k-1] = result[k-1]  + signature
                }else{
                  result.push("<br />"+signature)
                }
              }
            } else{
              if(hasSignature && this.showSignatureSettings){
                result.pop()
              }
            }
            if(result.length > 1){
              this.text = result.join('---')
            }else{
              if(result.length === 1){
                this.text = result[0]
              }
            }
          }else{
            if(signature && this.showSignatureSettings){
              this.text = this.text +line+"<br />" + signature
            }
          }
        } else{
          if(signature && this.showSignatureSettings){
            signature = signature.replace('<br />', '\n').replace(/&nbsp;/g, '\n')
            signature = strip_tags(signature)
          }
          if(k > 1){
            if(signature && this.showSignatureSettings){
              if(hasSignature){
                if(line===''){
                  result[k-2] = result[k-2]  + signature
                  result.pop()
                }else{
                  result[k-1] =  "\n"+ signature
                }
              }else{
                if(line===''){
                  result[k-1] = result[k-1] + "\n" + signature
                }else{
                  result.push("\n"+signature)
                }
              }
            } else{
              if(hasSignature && this.showSignatureSettings){
                result.pop()
              }
            }
            if(result.length > 1){
              this.text2 = result.join('---')
            }else{
              if(result.length === 1){
                this.text2 = result[0]
              }
            }
          }else{
            if(signature && this.showSignatureSettings){
              this.text2 = this.text2 +line+"\n" + signature
            }
          }
        }
      }
    },
    onEditorReady(ed){
      if(ed && 'editing' in ed && 'view' in ed.editing && 'focus' in ed.editing.view && (this.to_all !== 0 || (this.to_all === 0 && this.email_to)) && this.to_all !== 3)
        ed.editing.view.focus();
    },
    initMail(){
      if(this.mail && this.mail.content_type && this.mail.content_type === "text/plain"){
        this.isHTML = true
      }
      if(this.mail && this.mail.content_type){
        this.isAvailableModeButton = false
      }
      if(this.to_all === 3){
        this.text = this.textForResend()
        this.text2 = this.textForResend()
        if(this.mail && this.mail.subject){
          this.mail_subject = "Fwd: " + this.mail.subject
        }
      } else if(this.to_all === 2 || this.to_all === 1){
        this.mail_subject = "Re: " + this.mail.subject

        if(this.mail.fields && this.mail.fields.from && this.mail.fields.from.length > 0){
          const arr = []
          this.mail.fields.from.forEach(el => arr.push(el.name || el.email))
          let date = ''
          if(this.mail.created_at){
            date = new Date(this.mail.created_at);
            let results = ''
            try{
              results = date.toLocaleString(this.lang, {hour: "2-digit", minute:"2-digit", second:"2-digit", day: '2-digit', month: 'short' });
              date = results
            }catch(e){
              results = date.toUTCString().split(" ");
              date = results[4] + " " + results[1] + " " + results[2];
            }
          }
          let content = ''
          if(this.mail.content){
            try{
              if(this.mail.encrypted){
                content = this.mail.content
              }else{
                content = decodeURIComponent(escape(atob(this.mail.content)))
              }
            } catch (err){
              console.log("error", err.message)
              content = ''          
            }
          }
          if(this.isHTML){
            this.text = "<br /><br /><p>" + arr.join(', ') + " "+ this.$t('wrote') +" " + date + ": </p><blockquote><p>" +
            content
            + "</p></blockquote><br />"
          }else{
            let arrContent = content.split(/\r?\n|\r|\n/g)
            let newArrContent = []
            arrContent.forEach(el => newArrContent.push('> '+el))
            content = newArrContent.join('\n')
            this.text2 = '\n\n' + arr.join(', ') + " "+ this.$t('wrote') +" " + date + ':\n' +
            content
            + '\n'
          }
        }
      }
      this.editorConfig.language = this.lang
      if(this.mail && this.mail.fields && ((this.mail.fields.from && this.mail.fields.from.length > 0) || (this.mail.fields['reply-to'] && this.mail.fields['reply-to'].length > 0))){
        if(this.to_all === 1){
          if(this.mail.fields['reply-to'] && this.mail.fields['reply-to'].length > 0){
            this.receivers_emails = this.mail.fields['reply-to']
          } else {
            this.receivers_emails = this.mail.fields.from
          }
        } else if(this.to_all === 2){
          let to = []
          if(this.mail.fields.to && this.mail.fields.to.length > 0){
            const als = this.$store.getters["getAccount"].aliases 
            if(als && als.length > 0){
              to = this.mail.fields.to.filter(el => {
                if(el.email === this.email) return false
                const k = als.findIndex(elem => elem.email === el.email)
                if(k!==-1) return false
                return true
              })
            } else{
              to = this.mail.fields.to.filter(el => el.email !== this.email)
            }
          }
          if(this.mail.fields.reply-to && this.mail.fields.reply-to.length > 0){
            to = to.concat(this.mail.fields.reply-to);
          } else {
            if(this.mail.fields.from && this.mail.fields.from.length > 0)
              // to = [this.mail.fields.from[0]].concat(to)
              to.unshift(this.mail.fields.from[0])
          }
          this.receivers_emails = to
          if(this.mail.fields.cc && this.mail.fields.cc.length > 0){
            this.receivers_emails_copy = this.mail.fields.cc
            this.copyIsOpen = true
          }
        }
      }
      if(this.email_to){
        let arr = this.email_to.split(',')
        arr.forEach(el => {
          this.receivers_emails.push({email: el, name: ''})
        })
      }
      if(this.mail && this.mail['isDraft']){
        if(this.mail.fields.to && this.mail.fields.to.length > 0){
            this.receivers_emails  = this.mail.fields.to
          }
        if(this.mail.fields.cc && this.mail.fields.cc.length > 0){
          this.receivers_emails_copy = this.mail.fields.cc
          this.copyIsOpen = true
        }
        if(this.mail.fields['reply-to'] && this.mail.fields['reply-to'].length > 0){
            this.receivers_emails_whom_to_answer = this.mail.fields['reply-to']
            this.whomToAnswerIsOpen = true
          }
        if(this.mail.fields.bcc && this.mail.fields.bcc.length > 0){
          this.receivers_emails_hidden_copy = this.mail.fields.bcc
          this.hiddenCopyIsOpen = true
        }
        if(this.mail.subject){
          this.mail_subject = this.mail.subject
        }
        if(this.mail.subject){
          this.subject= this.mail.subject
        }      
        if(this.mail.content){
          try{
              this.text = decodeURIComponent(escape(atob(this.mail.content)))
              this.text2 = decodeURIComponent(escape(atob(this.mail.content)))
          }catch(err){
            this.error = err.message
            this.widthPB()
          }
        }
      }
      const em = this.$store.getters['getLeftSidebarSelect']
      if(this.account && this.account.aliases && Array.isArray(this.account.aliases) && this.email || this.$store.getters["getAccount"].aliases){        
        let aliases = []
        let email = ''
        if(this.$store.getters["getAccount"].aliases && this.$store.getters["getAccount"].aliases.length >0){
          aliases = this.$store.getters["getAccount"].aliases
        } else if(this.account.aliases && this.account.aliases.length > 0){
          aliases = this.account.aliases.slice()
        }
        if(this.$store.getters['user'].Email){
          email = this.$store.getters['user'].Email
        } else if(this.email){
          email = this.email
        }
        const userAlias = this.$store.getters['user'].Info?.alias
        const i = aliases.findIndex(el => el.email === email)
        if(i===-1){
          let arr = []
          if(!userAlias) {
            arr.push({id: 1000000 + Math.floor(Math.random() * 1000000), email: email})
          }
          arr = arr.concat(aliases)
          if(this.mail && this.mail['isDraft']){
            if(this.mail.fields && this.mail.fields.from && this.mail.fields.from.length > 0){
              const eml = this.mail.fields.from[0].email
              const j = arr.findIndex(el => el.email === eml)
              if(j !== -1){
                const element = arr[j]
                arr.splice(j, 1)
                arr = [element].concat(arr)
              }
            }
          } else{
            const j = arr.findIndex(el => el.email === em)
            if(j !== -1){
              const element = arr[j]
              arr.splice(j, 1)
              arr = [element].concat(arr)
            }
          }
          this.accountAliases = arr
        } else{
          let arr2 = aliases.slice()
          if(this.mail && this.mail['isDraft']){
            if(this.mail?.fields?.from && this.mail.fields.from.length > 0){
              const eml = this.mail.fields.from[0].email
              const j = arr2.findIndex(el => el.email === eml)
              if(j !== -1){
                const element = arr2[j]
                arr2.splice(j, 1)
                arr2 = [element].concat(arr2)
              }
            }
          } else{
            const k = aliases.findIndex(el => el.email === em)
            if(k !== -1){
              const element = arr2[k]
              arr2.splice(k, 1)
              arr2 = [element].concat(arr2)
            }
          }
          this.accountAliases =  arr2
        }
        if(this.$store.getters["getAccount"] && this.$store.getters["getAccount"].profiles){
          this.profiles = this.$store.getters["getAccount"].profiles
        }
        if(em && em !== this.$t('all_messages')){
          this.aliasesEmails.push(this.accountAliases[0].email)
          this.aliasesId.push(this.accountAliases[0].id)
        }
        const k = this.profiles.findIndex(el => el.default)
        if(k > 0){
          let a = this.profiles[k]
          this.profiles[k] = this.profiles[0]
          this.profiles[0] = a
        }
        if(!userAlias) {
          this.profiles.forEach(el => {
            this.aliasesEmails.push(el.name + ' <' + el.email + '>')
            this.aliasesId.push(10000000 + el.id)
          })
        } else {
          const l = this.profiles.findIndex(el => el.email === userAlias)
          if(l !== -1){
            const my_profile = this.profiles[l]
            this.aliasesEmails.push(my_profile.name + ' <' + my_profile.email + '>')
            this.aliasesId.push(10000000 + my_profile.id)
          }
        }
        if(em && em !== this.$t('all_messages')){
          this.accountAliases.slice(1,).forEach(el => {
            this.aliasesEmails.push(el.email)
            this.aliasesId.push(el.id)
          })
        }else{
          this.accountAliases.forEach(el => {
            this.aliasesEmails.push(el.email)
            this.aliasesId.push(el.id)
          })
        }
        if(this.mail?.fields?.to  && this.mail.fields.to.length > 0 && this.mail.fields.to[0].email){
          let i = -1
          i = this.findEmailInList(this.mail.fields.to)
          if(i!==-1){
            this.changeOrder(i)
          } else if(this.mail.fields.cc  && this.mail.fields.cc.length > 0 ) {
            i = this.findEmailInList(this.mail.fields.cc)
            if (i !== -1)
              this.changeOrder(i)
            else if (this.mail.fields.bcc && this.mail.fields.bcc.length > 0) {
              i = this.findEmailInList(this.mail.fields.bcc)
              i !== -1 && this.changeOrder(i)
            }
          }
        }
        this.search_alias = this.aliasesEmails[0]
      }
      if(this.mail?.attachments && this.mail.attachments.length > 0){
        this.savedAttachments = this.mail.attachments.slice()
      }
      const settings = this.$store.getters['getUserSettings']
      if(settings && settings.id){
        this.settings = settings
        if(this.settings.signature_reply && (this.settings.signature_add === 'always' 
            || (this.settings.signature_add === 'response' && this.to_all >= 1)
            || (this.settings.signature_add === 'new_message' && this.to_all === 0))) this.initSignature()
      }else{
        const account = this.$store.getters['getAccount']
        if(account?.settings){
          this.settings = account.settings
        }else{
          this.getSettings()
        }
      }
    },
    findEmailInList(arr){
      const hash = {}
      arr.forEach(el => hash[el.email?.toLowerCase()] = {...el})
      let i = -1
      this.profiles.forEach(el => {
        if(hash[el.email]) {
          const str = el?.name + ' <' + el.email + '>'
          i = this.aliasesEmails.indexOf(str)
          return
        }
      })
      if(i===-1){
        this.aliasesEmails.forEach((el,j) => {
          if(hash[el]) {
            i = j
            return
          }
        })
      }
      return i
    },
    changeOrder(i){
      const element = this.aliasesEmails[i]
      this.aliasesEmails.splice(i, 1)
      this.aliasesEmails = [element].concat(this.aliasesEmails)
      const element2 = this.aliasesId[i]
      this.aliasesId.splice(i, 1)
      this.aliasesId = [element2].concat(this.aliasesId)
    },
    nextTab(event, i){
      event.preventDefault()
      this.tab = i
      const firstInput = document.getElementById(`tab${this.tab}`)
      if(firstInput)  firstInput.focus()
    },
    async getSettings(){
      try {
        await this.$store.dispatch("getSettings");
        this.settings = this.$store.getters['getUserSettings']
        if(this.settings.signature_reply && (this.settings.signature_add === 'always' 
          || (this.settings.signature_add === 'response' && this.to_all >= 1)
          || (this.settings.signature_add === 'new_message' && this.to_all === 0))) this.initSignature()
      }catch (err) {
        this.errorHandler(err)
      }
    },
    initSignature(){
      this.showSignatureSettings = true
      const em = this.$store.getters['getLeftSidebarSelect']
      let mailIsDraft = false
      if(this.mail && this.mail.flags){
        const l = this.mail.flags.indexOf('draft')
        if(l!==-1){
          mailIsDraft = true
        }
      }
      if((em === this.$t('all_messages') || em === '') && this.profiles.length > 0 && this.profiles[0].signature && !mailIsDraft){
        let signature = this.profiles[0].signature
        let line = '---'
        if(signature){
          const res1 = signature.split("---")
          if(res1.length > 1){
            line = ''
          }
        }
        if(this.isHTML){
          let br = '<br />'
          if(line ===''){
            br = ''
          }
          this.text = this.text + '<br />'+line+br + signature
        } else{
          let br = "\n"
          if(line ===''){
            br = ''
          }
          signature = signature.replace('<br />', '\n')
          signature = strip_tags(signature)
          this.text2 = this.text2 + "\n"+line+ br + signature
        }
      }
    },
    tryParseJSONObject (jsonString){
      try {
        var o = JSON.parse(jsonString);
        if (o && typeof o === "object") {
          return o;
        }
      }catch (e) {
        console.log('error',e)
      }
      return false;
    }
  },
  created() {
    this.urlFileUpload = this.$store.getters['url1']+'attachments'
    this.initMail()
    this.userAlias = this.$store.getters['user'].Info?.alias
      this.interval = setInterval(()=>{
        if(!this.isCollapsed) this.onSendMail(true, true)
      },30000)
      const th = this
      document.onkeydown = function (event) {
        // console.log('event :>> ', event);
        if (event.which == 9 || event.keyCode == 9) {
          switch(th.tab) {
            case 0:
              th.nextTab(event, 1)
              break;
            case 1:
              if(!th.showOtherInputs) th.nextTab(event, 5) 
              else th.nextTab(event, 2) 
              break;
            case 2:
              if(!th.showOtherInputs) th.nextTab(event, 5) 
              else th.nextTab(event, 3) 
              break;
            case 3:
              if(!th.showOtherInputs) th.nextTab(event, 5) 
              else th.nextTab(event, 4) 
              break;
            case 4:
              if(!th.showOtherInputs) th.nextTab(event, 5) 
              else th.nextTab(event, 5) 
              break;
            case 5:
              if(!th.isHTML) th.nextTab(event, 6) 
              else {
                const elem = document.getElementById(`tab5`)
                if(elem){
                   elem.focus()
                }
                th.tab = 0
              }
              break;
            case 6:
              th.nextTab(event, 1) 
              break;
            case 7:
              th.nextTab(event, 1) 
              break;
            default:
          }
        }
        if (event.ctrlKey && event.keyCode == 13 || event.metaKey && event.keyCode == 13) {
          event.preventDefault();
          if(!th.isCollapsed) th.onSendMail(false, false);
        }
      };
      this.code = makeid(20)
      
      if (window.matchMedia('(max-width: 400px)').matches) {
        this.isMobile = true
      }
    window.onresize = function() {
      if (window.matchMedia('(max-width: 400px)').matches) {
        th.isMobile = true
      }
      if (window.matchMedia('(min-width: 401px)').matches) {
        th.isMobile = false
      }
    }
    window.addEventListener('click', (e) => {
    // && !e.target.closest('.user')
      if(!e.target.closest('.contact_list_to') && this.contacts_list.length > 0) {
        this.contacts_list = []
      }
    });
  },
  mounted(){
    // document.dispatchEvent(new KeyboardEvent('keydown', {'keyCode': 9}));
    Dropzone.autoDiscover = false;
    const that = this;
    new Dropzone("#upload",{
          clickable: ".dropzone",
          url: this.urlFileUpload,
          previewsContainer: "#previewsContainer",
          previewTemplate: `
          <div class="attach dz-preview dz-file-preview" > 
            <div class="attach__preview dz-details">
              <div class="attach__image icon" data-dz-thumbnail></div>

<!--              <img class="attach__image" data-dz-thumbnail />-->
            </div>
            <div class="attach__row dz-progress">
              <span class="dz-upload attach__progress" data-dz-uploadprogress></span>
            </div>
            <div class="attach__row"> 
              <p class="attach__name">
              <span class="dz_name" data-dz-name tabindex="0"></span>
              </p>
              <button class="button button--action" data-dz-remove ><span class="button__icon icon icon-close"></span></button>
            </div>

            <div class="attach__row"> 
<!--               <div class="attach__format"></div>-->
              <div class="attach__error dz-error-message"><span class="attach__error-text" data-dz-errormessage tabindex="0"></span></div>
             
              <div class="attach__weight" data-dz-size></div>
            </div>
          </div>
          `,
          uploadMultiple: false,
          autoProcessQueue: true,
          parallelUploads: 4,
          error(file, response) {
            if(file?.upload?.uuid){
              const i = that.processingAttaches.findIndex(el => el === file.upload.uuid)
              if(i!==-1)
                that.processingAttaches.splice(i,1)
            }
            let err
            const resp = that.tryParseJSONObject(response)
            if(resp?.error?.message){
              err = resp['error']['message']
            }else if(typeof response === 'string'){
              err = response
            }else{
              err = that.$t('internal_error')
            }
              file.previewElement.classList.add("dz-error");
              let _ref = file.previewElement.querySelectorAll("[data-dz-errormessage]");
              let _results = [];
              for (let _i = 0, _len = _ref.length; _i < _len; _i++) {
                  let node = _ref[_i];
                  _results.push(node.textContent = err);
              }
              return _results;
          },
          processing(file) {
            if(file?.upload?.uuid)
              that.processingAttaches.push(file.upload.uuid)
          },
          success(file, response){
          // :class="'icon-format-'+getFileExt(attach.name.split('.').pop())"
            if(file?.upload?.uuid){
              const i = that.processingAttaches.findIndex(el => el === file.upload.uuid)
              if(i!==-1)
                that.processingAttaches.splice(i,1)
            }
            let previewImg = file.previewElement.querySelector('.attach__image');
            // let previewFormat = file.previewElement.querySelector('.attach__format');
            // let previewImg = file.previewElement.querySelector('img');
            var ext = file.type;
            let arr = ext.split("/")
            if(arr.length > 0){
              ext = arr[arr.length-1]
            }
            let ext2 = file.name.split('.').pop()
            // previewFormat.textContent = ext2
            // var newImagePath = file.name;
            let fileType = fileExt(ext2)
            // if (ext != 'png' && ext != 'jpg' && ext != 'jpeg' && ext != 'gif') {
                    // if (ext == 'pdf') {
                    //     newImagePath = "/images/icon_pdf128.png";
                    // }else{
                    //     newImagePath = "images/Attach-preview.png";
                    // }
                    previewImg.classList.add('icon-format-'+fileType)
                    // previewImg.src = newImagePath;
                // }
            // class="attach__error-text"
              if(response['attachment']){
                that.attachments.push(response.attachment)
                if(response.attachment.id){
                  file.uid = response.attachment.id
                }
              }
              file.uid = response.attachment.id
              return file.previewElement.classList.add("dz-success");
          },
          removedfile(file){
            const i = that.attachments.findIndex(el => el.id === file.uid)
            if(i!==-1){
              that.attachments.splice(i,1)
            }
            file.previewElement.remove();
          },
          init() {
            var myDropzone = this;
            this.element.querySelector("#add_attachments")
            .addEventListener("click", function(e){
              // console.log("e", e)
              e.preventDefault();
              e.stopPropagation();
              myDropzone.processQueue();
            });
          },
        });
          // error(file, errormessage, xhr){
          //   let errorDisplay = document.querySelectorAll('[data-dz-errormessage]');
          //   errorDisplay[errorDisplay.length - 1].innerHTML = xhr.statusText
          //   // var response = JSON.parse(xhr.responseText);
          // }

      if((this.to_all === 0 && !this.email_to) || this.to_all === 3 ){
        const elem = document.getElementById(`tab1`)
        if(elem){
            elem.focus()
        }
      }
  }
};
</script>
<style>

.mail__body { 
  flex-grow: 1;
}

.mail__button--files::before { 
  transform: translateY(-50%) rotate(-180deg);
}

.mail__button--files.collapsed::before { 
  transform: translateY(-50%) rotate(0);
}

.ckeditor_wrapper{
  height: 100%;
}
.error_class{
  color: red;
}
#file-upload {
    display: none;
}
.attach{
    font-family: "Manrope";
    font-size: 14px;
    line-height: 144%;
    font-weight: 400;
    font-style: normal;
}
.saved_attachments{
  padding: 0px;
}

.mobile_creating_mail_btn{
  top: -10px;
}
.creating_mail_btn.lds-ellipsis div{
  background: #0a0f13 !important;
}
.dz_name:focus, .attach__error-text:focus,
.attach_name:focus{
    position: absolute;
    background: white;
    max-width: 200px;
    max-height: 64px;
    border: 1px solid black;
    z-index: 123;
    display: block;
    overflow: hidden;
    white-space: break-spaces;
    padding: 0 5px; 

}
.ck.ck-editor {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.ck.ck-editor__main {
  flex-grow: 1;
}
.ck.ck-content {
  height: 100%;
}
.ck-editor__editable[role="textbox"] {
  max-height: none !important;
}
.ck-editor__editable[role="textbox"], .textarea_class {
    min-height: 200px !important;
    font-family: "Manrope";
    font-size: 14px;
    line-height: 144%;
    font-weight: 400;
    font-style: normal;

    max-height: 300px;
    overflow: auto;
}
.textarea_class{
  width: 100%;
  padding: 10px;
  height: 100%;
  max-height: unset;
}
.ck-content ul{
  display: block ;
  list-style-type: disc ;
  margin-block-start: 1em ;
  margin-block-end: 1em ;
  margin-inline-start: 0px ;
  margin-inline-end: 0px ;
  padding-inline-start: 40px ;
}
.ck-content ol{
  display: block ;
  list-style-type: decimal ;
  margin-block-start: 1em ;
  margin-block-end: 1em ;
  margin-inline-start: 0px ;
  margin-inline-end: 0px ;
  padding-inline-start: 40px ;
}
.dz-upload { 
    display: block; 
    background-color: green; 
    height: 5px;
    width: 0%;
}
.dz-progress{
  height: 5px;
}
.dz-success .dz-progress .dz-upload{
    background-color: green; 
}
.dz-error .dz-progress .dz-upload{
    background-color: red; 
}
.new-mail__button--add.dropzone.dz-clickable{
    min-height: unset;
}
.dz-error{
  border-radius: 8px 8px 4px 4px;
}
.dz-error .attach__preview{
  display: none;
}
.dz-error .attach__row{
  width: 100%;
}
.dz-error .attach__progress{
  width: 100%;
  border-radius: 8px 8px 0 0;
}
.dz-preview:not([class*="dz-success"]){ 
  border-radius: 8px 8px 4px 4px;
 }
 .dz-preview:not([class*="dz-success"]) .attach__preview{
  display: none;
}
.dz-preview:not([class*="dz-success"]) .attach__row{
  width: 100%;
}
.dz-preview:not([class*="dz-success"]) .attach__progress{
  width: 100%;
  border-radius: 8px 8px 0 0;
}

</style>
