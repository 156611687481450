<template>
    <div class="custom-select">
    <div 
        @focusout="handleFocusOut"
        tabindex="0">
        <div class="custom-select__selected icon icon-arrow-dropdown"
            :class="{'custom-select__selected--active': active, 'cursor_class': disabled}"
            @click="clickOnSelectedValue">{{actual_value}}</div>
        <div class="custom-select__menu"
            :class="{'custom-select__menu--hide': !active}">
            <div class="custom-select__item" 
            v-for="(val,i) in select_values" 
            :class="{'custom-select__item--selected': actual_value === val}"
            @click="selectNewValue(_values[i])" :key="val">{{val}}</div>
        </div>
    </div>
    </div>
</template>
<script>
export default {
  name: "VueSelect",
  emits:['change_value'],
  props: {
      select_values: Array,
      _values: Array,
      actual_value: String,
      disabled: Boolean
  },
  data() {
      return {
        active: false,
        // popper
      }
  },
  methods: {
        selectNewValue(val){
            //   this.$refs.refSelectLettersPerPage.selectedIndex = i
            this.$emit('change_value', val)
            this.active = !this.active
        },
        clickOnSelectedValue(e){
          if(this.disabled) {
            return
          }
            e.stopPropagation();
            this.active = !this.active
        },
        handleFocusOut(){
            this.active = false
        }
    },
    mounted(){
        // this.popper = Popper.createPopper(trigger, options, {
        // placement: 'bottom-start',
    // })
    }
}
</script>
<style scoped>
.cursor_class{
  cursor: default;
  color: gray;
}
</style>
